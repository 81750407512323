/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import fetchProduct from 'src/functions/products/fetchProduct';
import updateProduct from 'src/functions/products/editProduct';
import { ToastContainer, toast } from 'react-toastify';

const SingleProduct = () => {
  const navigate = useNavigate();
  const [product, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [userData, setUserData] = useState({});
  const dispatch = useDispatch();

  const { users } = useSelector((state) => state.user);
  const {
    products: productsData,
    loading: loadingProduct,
    error: errorProduct,
  } = useSelector((state) => state.product);
  const { id } = useParams();

  function reset() {
    const title = document.getElementById('title');
    const description = document.getElementById('description');
    const price = document.getElementById('price');
    const discount = document.getElementById('discount');
    const image = document.getElementById('image');

    title.value = product?.title;
    description.value = product?.description;
    price.value = product?.price;
    discount.value = product?.discount;
    image.value = product?.image;
  }

  async function saveForm() {
    if (!!userData.title || !!userData.description || !!userData.price || !!userData.discount || !!userData.image) {
      const res = await updateProduct(dispatch, users?.token, id, userData);
      if (res) {
        setEditForm(false);
        toast.success('Product updated successfully');
      } else {
        toast.error('Failed to update product');
      }
    } else {
      toast.error('Nothing changed');
      setEditForm(false);
    }
  }

  function deleteProduct() {
    const url = process.env.REACT_APP_URL;
    setLoading(true);
    fetch(`${url}/api/products/${id}`, {
      method: 'DELETE',
    })
      .then((response) => response.json())
      .then((data) => {
        fetchProduct(dispatch, users?.token);
        setTimeout(() => {
          navigate('/dashboard/products');
        }, 200);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  async function fetchProducts() {
    if (productsData?.length !== 0) {
      const product = productsData?.find((product) => product?.slug === id);
      if (!product) {
        setError(true);
      } else {
        setProducts(product);
        setLoading(false);
      }
    } else {
      const produs = await fetchProduct(dispatch, users?.token);
      const product = produs.find((product) => product?.slug === id);
      if (!product) {
        setError(true);
      } else {
        setProducts(product);
        setLoading(false);
      }
    }
  }

  function visit() {
    window.open('https://casemandu.com.np/product/' + product?.slug, '_blank');
  }

  useEffect(() => {
    if (!!id) {
      fetchProducts();
    }
  }, [id, productsData]);

  if (error) {
    return (
      <div className="container mx-auto p-8 gap-2 flex flex-col">
        <h1 className="text-3xl font-semibold mb-4">Product not found</h1>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="container mx-auto p-8 gap-2 flex flex-col">
        <h1 className="text-3xl font-semibold mb-4">Loading...</h1>
      </div>
    );
  }

  return (
    <div className="relative container mx-auto p-8 gap-2 flex flex-col">
      {/* add a backbutton that goes back */}
      <div className="flex" onClick={() => navigate('/dashboard/products')}>
        <div className="flex h-10 w-10 justify-center items-center bg-blue-gray-300 rounded-full hover:bg-blue-gray-400 text-white hover:cursor-pointer transition-all">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className=" h-6 w-6 mr-2 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </div>
      </div>
      <ToastContainer />

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div>
          <img src={product?.image} alt={product?.title} className="w-full h-auto" />
        </div>
        <div className="col-span-2">
          <h1 className="text-3xl font-semibold mb-4">{product?.title}</h1>
          <p className="text-gray-500 mb-4">{product?.description}</p>
          <div className="flex items-center mb-4">
            {product?.new && (
              <span
                className={`mr-2 px-2 py-1 rounded ${
                  product?.new ? 'bg-green-500 text-white' : 'bg-gray-500 text-white'
                }`}
              >
                {product?.new ? 'New' : null}
              </span>
            )}
            {product?.discount > 0 && (
              <h2>
                <span className="mr-2 px-2 py-1 bg-red-500 text-white">Sale</span>
                {product?.discount}% off
              </h2>
            )}
          </div>
          <div className="flex items-center mb-4">
            <span className="text-xl font-semibold">NPR. {product?.price}</span>
            {product?.discount > 0 && (
              <span className="ml-2 text-gray-500 line-through">NPR. {product?.price + product?.discount}</span>
            )}
          </div>
          <div className="flex flex-wrap gap-2">
            <button
              onClick={() => setEditForm(true)}
              className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Edit
            </button>
            {/* generate a delete button */}
            <button
              onClick={deleteProduct}
              className="bg-red-500 text-white px-4 py-2 rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            >
              Delete
            </button>
            <button
              onClick={visit}
              className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              Visit
            </button>
          </div>
        </div>
      </div>

      {editForm && (
        <div
          className="fixed top-0 left-0 w-full backdrop-blur-md flex justify-center items-center h-screen transition-all"
          style={{ zIndex: 9999, backgroundColor: '#000000A0' }}
        >
          <div className="flex md:flex-row flex-col h-4/5 overflow-y-scroll w-8/12">
            <form id="editForm" className="w-full  max-w-lg scroll-m-28 bg-white shadow-md p-8">
              <h2 className="text-2xl font-semibold mb-4">Edit Product</h2>
              {/* Title */}
              <div className="mb-4">
                <label htmlFor="title" className="block text-gray-700 font-semibold mb-2">
                  Title
                </label>
                <input
                  defaultValue={product?.title}
                  onChange={(e) => {
                    setUserData({ ...userData, title: e.target.value });
                  }}
                  type="text"
                  id="title"
                  className="w-full outline-none bg-gray-200 p-2 border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              {/* Description */}
              <div className="mb-4">
                <label htmlFor="description" className="block text-gray-700 font-semibold mb-2">
                  Description
                </label>
                <textarea
                  defaultValue={product?.description}
                  onChange={(e) => {
                    setUserData({ ...userData, description: e.target.value });
                  }}
                  id="description"
                  className="w-full outline-none bg-gray-200 p-2 border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  rows="4"
                ></textarea>
              </div>
              {/* Price */}
              <div className="mb-4">
                <label htmlFor="price" className="block text-gray-700 font-semibold mb-2">
                  Price
                </label>
                <input
                  defaultValue={product?.price}
                  onChange={(e) => {
                    setUserData({ ...userData, price: e.target.value });
                  }}
                  type="number"
                  id="price"
                  className="w-full outline-none bg-gray-200 p-2 border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              {/* Discount */}
            </form>
            <div className="flex flex-col p-6 bg-white">
              <div className="mb-4">
                <label htmlFor="image" className="block text-gray-700 font-semibold mb-2">
                  Image
                </label>
                <input
                  defaultValue={product?.image}
                  onChange={(e) => {
                    setUserData({ ...userData, image: e.target.value });
                  }}
                  type="text"
                  id="image"
                  className="w-full outline-none bg-gray-200 p-2 border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <img src={product?.image} alt={product?.title} className="w-auto object-contain h-4/5" />
              <div className="flex gap-2 justify-end">
                <button
                  onClick={() => setEditForm(false)}
                  type="button"
                  className="bg-gray-500 text-white px-4 py-2 rounded-full hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  Cancel
                </button>

                <button
                  onClick={() => reset()}
                  type="button"
                  className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Reset
                </button>

                <button
                  type="submit"
                  onClick={saveForm}
                  className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleProduct;
