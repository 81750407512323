async function getTypes(dispatch, token) {
  const url = process.env.REACT_APP_URL; // Replace with your actual product URL

  try {
    const response = await fetch(url + '/api/casetypes', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch product');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export default getTypes;
