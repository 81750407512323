import { fetchBrandFailure, fetchBrandRequest, fetchBrandSuccess } from 'src/reducer/brandReducer';

async function getAllBrand(dispatch, token) {
  const url = process.env.REACT_APP_URL; // Replace with your actual product URL

  try {
    dispatch(fetchBrandRequest());

    const response = await fetch(url + '/api/phones/brands', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch product');
    }

    const brand = await response.json();
    dispatch(fetchBrandSuccess(brand));
    return brand;
  } catch (error) {
    console.error(error);
    dispatch(fetchBrandFailure(error));
    throw error;
  }
}

export default getAllBrand;
