/* eslint-disable */
import { func } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function BannerImage() {
    const url = process.env.REACT_APP_URL
    const [loading, setLoading] = useState(true)
    const [images, setImages] = useState([])
    const [image, setImage] = useState(null)
    const [imageUrl, setImageUrl] = useState(null)
    const [videoLink, setVideoLink] = useState(null)
    const [link, setLink] = useState(null)
    function fetchSliderImages() {
        fetch(`${url}/api/banners`)
            .then(res => res.json())
            .then(data => {
                setLoading(false)
                setImages(data)
            })
    }

    function getImage(e) {
        setImage(e.target.files[0])
        // image url
        const imgUrl = URL.createObjectURL(e.target.files[0])
        setImageUrl(imgUrl)
    }

    function upload(e) {
        e.preventDefault()
        if(!videoLink){
        const formData = new FormData()
            formData.append('image', image) 
            fetch('https://api.imgbb.com/1/upload?key=' + process.env.REACT_APP_IMGBB, {
                method: 'POST',
                body: formData
            }
            )
            .then(res => res.json())
            .then(data => {
                const imageUrl = data.data.display_url
                fetch(`${url}/api/banners/${clickedData._id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        image: imageUrl,
                        link: link,
                        display: clickedData.display
                    })
                })
                    .then(res => res.json())
                    .then(data => {
                        fetchSliderImages()
                        setShowAdd(false)
                        setImage(null)
                        setImageUrl(null)
                        addedNotify()
                    })
            })
        }else{
            fetch(`${url}/api/banners/${clickedData._id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        image: videoLink,
                        link: link,
                        display: clickedData.display
                    })
                })
                    .then(res => res.json())
                    .then(data => {
                        fetchSliderImages()
                        setShowAdd(false)
                        setImage(null)
                        setImageUrl(null)
                        addedNotify()
                    })
        }
        
    }


    const deleteNotify = () => toast("The selected Image deleted successfully!");
    const addedNotify = () => toast("Image added successfully!");



    useEffect(() => {
        fetchSliderImages()
    }
        , [])

    const [showAdd, setShowAdd] = useState(false)
    const [clickedData, setClickedData] = useState(null)

    function showAddf(e) {
        setShowAdd(true)
        setLink(e.link)
        setImageUrl(e.image)
        setClickedData(e)
    }

    if (loading) return (<h1>Loading...</h1>)

    return (
        <div className='flex flex-col gap-2'>
            <div className='flex pb-2 border-b-2 flex-row gap-3 justify-between'>
                {/* generate a back button */}
                <div className="flex gap-3 items-center">
                    <button className='bg-blue-500 hover:bg-blue-700 text-white h-8 w-8 flex justify-center items-center font-bold py-2 px-4 rounded-full'>
                        &lt;-
                    </button>
                    {/* generate a header with asd written */}
                    <h1 className='text-xl font-bold'>
                        ({images.length}) Banner Images
                    </h1>
                </div>
                <ToastContainer />
                {/* generate a button with add new image written */}
            </div>

            {(showAdd) && <div className="flex gap-5 flex-col border-t-2 border-red mt-5 pt-5">
                <h2 className='text-2xl'>Update the Banner {clickedData.title}</h2>
                <div className="flex flex-col gap-3">
                    <div className="flex flex-col gap-3">
                        <label htmlFor="link">Display</label>
                        <input defaultValue={clickedData.display} readOnly className='border-2 border-gray-400 p-2 rounded' type="text" name="link" id="link" />
                    </div>
                    <div className="flex flex-col gap-3">
                        <label htmlFor="link">Link</label>
                        <input defaultValue={clickedData.link} onChange={(e) => setLink(e.target.value)} className='border-2 border-gray-400 p-2 rounded' type="text" name="link" id="link" />
                    </div>
                    <div className="flex flex-col gap-3">
                        <label htmlFor="image">Image</label>
                        <input onChange={getImage} className='border-2 border-gray-400 p-2 rounded' type="file" name="image" id="image" />
                    </div>
                    {/* or */}
                    <p>Or</p>
                    <div className="flex flex-col gap-3">
                        <label htmlFor="image">Video Url</label>
                        <input onChange={(e) => setVideoLink(e.target.value)} className='border-2 border-gray-400 p-2 rounded' type="text" name="image" id="image" />
                    </div>

                   {
                    (imageUrl.endsWith('.mp4')) && <video className='h-52' src={imageUrl} controls></video>
                    || 
                    <img className='h-52 object-contain' src={imageUrl} alt="" />
                   }
                    <div className="flex gap-2">
                        <button onClick={upload} className='bg-blue-500 hover:bg-blue-700 text-white flex justify-center items-center py-2 px-4 rounded-full'>
                            Update
                        </button>
                        {/* add cancel button */}
                        <button onClick={() => setShowAdd(false)} className='bg-red-500 hover:bg-red-700 text-white flex justify-center items-center py-2 px-4 rounded-full'>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>}







            {/* generate a slider with images */}
            {(images?.length > 0) && <div className="flex gap-5 flex-wrap">
                {images.map((image, index) => {
                    // check link if it ends with .mp4 then it is a video
                    if (image?.image?.endsWith('.mp4')) {
                        return (<div className='flex flex-row' key={index}>
                            <div className="flex gap-3 bg-white p-2 hover:scale-105 transition-all rounded flex-col">
                                <video className='h-52' src={image?.image} controls></video>
                                <p>Routes: {image?.link}</p>
                                <p>Banner: {image?.display}</p>
                                <div className="flex gap-2">
                                    <button onClick={() => showAddf(image)} className='bg-blue-500 hover:bg-blue-700 text-white flex justify-center items-center py-2 px-4 rounded-full'>
                                        Update
                                    </button>
                                </div>
                            </div>
                        </div>
                        )
                    }else{

                        return (<div className='flex flex-row' key={index}>
                        <div className="flex gap-3 bg-white p-2 hover:scale-105 transition-all rounded flex-col">
                            <img className='h-52' src={image?.image} alt="" />
                            <p>Route: {image?.link}</p>
                            <p>Banner: {image?.display}</p>
                            <div className="flex gap-2">
                                <button onClick={() => showAddf(image)} className='bg-blue-500 hover:bg-blue-700 text-white flex justify-center items-center py-2 px-4 rounded-full'>
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    )
                }
                }
                )}

            </div>
                || <h1>No images found</h1>
            }

            <div className="flex flex-col border-t-2 border-red mt-5 pt-5">
                <h2 className='text-2xl'>Banner Image Demo</h2>
                <img className='max-w-full' src="https://i.ibb.co/18g7KNy/image-5.jpg" alt="bannerDemo" />
            </div>


        </div>
    )
}

export default BannerImage