import { configureStore } from '@reduxjs/toolkit';
import usersReducer from './reducer/usersReducer';
import productReducer from './reducer/productReducer';
import categoryReducer from './reducer/categoryReducer';
import ordersReducer from './reducer/ordersReducer';
import promoReducer from './reducer/promoReducer';
import brandReducer from './reducer/brandReducer';
import modelReducer from './reducer/modelReducer';

// import postsReducer from '../reducers/postsReducer';

// Automatically adds the thunk middleware and the Redux DevTools extension
const store = configureStore({
  // Automatically calls `combineReducers`
  reducer: {
    user: usersReducer,
    product: productReducer,
    category: categoryReducer,
    orders: ordersReducer,
    promo: promoReducer,
    brand: brandReducer,
    model: modelReducer,
    //
  },
});

export default store;
