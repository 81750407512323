// Define the action types
const FETCH_PROMO_REQUEST = 'FETCH_PROMO_REQUEST';
const FETCH_PROMO_SUCCESS = 'FETCH_PROMO_SUCCESS';
const FETCH_PROMO_FAILURE = 'FETCH_PROMO_FAILURE';

// Define the action creators
const fetchPromoRequest = () => {
  return {
    type: FETCH_PROMO_REQUEST,
  };
};

const fetchPromoSuccess = (promo) => {
  return {
    type: FETCH_PROMO_SUCCESS,
    payload: promo,
  };
};

const fetchPromoFailure = (error) => {
  return {
    type: FETCH_PROMO_FAILURE,
    payload: error,
  };
};

// Define the initial state
const initialState = {
  loading: false,
  promo: [],
  error: null,
};

// Define the promoReducer function
const promoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROMO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROMO_SUCCESS:
      return {
        ...state,
        loading: false,
        promo: action.payload,
      };
    case FETCH_PROMO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default promoReducer;
export { fetchPromoRequest, fetchPromoSuccess, fetchPromoFailure };
