async function Login(email, password) {
  const url = process.env.REACT_APP_URL;
  try {
    return await fetch(`${url}/api/users/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (!!data.message) {
          alert(data.message);
          const datatoSend = {
            success: false,
            message: data.message,
          };
          return datatoSend;
        } else {
          alert('Logged in successfully');
          const datatoSend = {
            success: true,
            message: 'Logged in successfully',
            data: data,
          };
          return datatoSend;
        }
      });
  } catch (error) {
    alert('Error:', error);
  }
}

export default Login;
