/* eslint-disable */
import { get } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const SingleOffer = () => {
  const navigate = useNavigate();

  const [product, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [editForm, setEditForm] = useState(false);

  const url = process.env.REACT_APP_URL;
  const { id } = useParams();

  function reset() {
    const title = document.getElementById('title');
    const description = document.getElementById('description');
    const price = document.getElementById('price');
    const discount = document.getElementById('discount');
    const image = document.getElementById('image');

    fetchProducts();

    title.value = product.title;
    description.value = product.description;
    price.value = product.price;
    discount.value = product.discount;
    image.value = product.image;
  }

  function saveForm() {
    const title = document.getElementById('title');
    const description = document.getElementById('description');
    const price = document.getElementById('price');
    const discount = document.getElementById('discount');
    const image = document.getElementById('image');

    if (
      title.value === '' ||
      description.value === '' ||
      price.value === '' ||
      discount.value === '' ||
      image.value === ''
    ) {
      alert('Please fill all the fields');
      return;
    }
    if (discount.value > 100) {
      alert('Discount cannot be more than 100%');
      return;
    }
    if (discount.value < 0) {
      alert('Discount cannot be less than 0%');
      return;
    }
    if (discount.value === 0) {
      alert('Discount cannot be 0%');
      return;
    }

    const data = {
      title: title.value,
      description: description.value,
      price: price.value,
      discount: discount.value,
      image: image.value,
      models: selectedModels,
    };
    updateProduct(data);
  }

  const [allmodels, setAllModels] = useState([]);
  const [models, setModels] = useState([]);
  const [brand, setBrand] = useState([]);

  function getModels(e) {
    fetch(`${url}/api/phones`)
      .then((res) => res.json())
      .then((data) => {
        // get model data and set it to models based on the product.models
        setBrand(data);
        const models = data.filter((model) => {
          return e.includes(model._id);
        });
        setModels(models);
        // push only id to selectedModels
        setSelectedModels(e);
      });
  }

  function updateProduct(e) {
    setLoading(true);
    fetch(`${url}/api/offers/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(e),
    })
      .then((response) => response.json())
      .then((data) => {
        setTimeout(() => {
          navigate('/dashboard/offers/' + data.slug);
        }, 200);

        setLoading(false);
        setEditForm(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  function deleteProduct() {
    setLoading(true);
    fetch(`${url}/api/offers/${id}`, {
      method: 'DELETE',
    })
      .then((response) => response.json())
      .then((data) => {
        setTimeout(() => {
          navigate('/dashboard/offers');
        }, 200);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  function fetchProducts() {
    fetch(`${url}/api/offers/${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.length === 0) {
          setError(true);
        }
        if (data.message === 'Product not found') {
          setError(true);
        }
        setProducts(data);
        setLoading(false);
        getModels(data.models);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        setLoading(false);
      });
  }
  const [selectedModels, setSelectedModels] = useState([]);

  function pushModels(e, id) {
    setSelectedModels([...selectedModels, e.target.value]);
    // reset select
    e.target.value = '';
  }

  function popModel(id) {
    const newModels = selectedModels.filter((model) => model !== id);
    setSelectedModels(newModels);
  }

  const [modelsName, setModelsName] = useState([]);

  useEffect(() => {
    if (id) {
      fetchProducts();
      getModels();
    }
  }, [id]);

  if (error) {
    return (
      <div className="container mx-auto p-8 gap-2 flex flex-col">
        <h1 className="text-3xl font-semibold mb-4">Product not found</h1>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="container mx-auto p-8 gap-2 flex flex-col">
        <h1 className="text-3xl font-semibold mb-4">Loading...</h1>
      </div>
    );
  }

  return (
    <div className="relative container mx-auto p-8 gap-2 flex flex-col">
      {/* add a backbutton that goes back */}
      <div className="flex" onClick={() => navigate('/dashboard/offers')}>
        <div className="flex h-10 w-10 justify-center items-center bg-blue-gray-300 rounded-full hover:bg-blue-gray-400 text-white hover:cursor-pointer transition-all">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className=" h-6 w-6 mr-2 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div>
          <img src={product.image} alt={product.title} className="w-full h-auto" />
        </div>
        <div className="col-span-2">
          <h1 className="text-3xl font-semibold mb-4">{product.title}</h1>
          <p className="text-gray-500 mb-4">{product.description}</p>
          <div className="flex items-center mb-4">
            {product.new && (
              <span
                className={`mr-2 px-2 py-1 rounded ${
                  product.new ? 'bg-green-500 text-white' : 'bg-gray-500 text-white'
                }`}
              >
                {product.new ? 'New' : null}
              </span>
            )}
            {product.discount > 0 && (
              <span className="mr-2 px-2 py-1 bg-red-500 text-white">Sale {product.discount}%</span>
            )}
            {/* render the models */}
          </div>
          <div className="mb-2">
            {models.map((model) => {
              return <span className="mr-2 px-2 py-1 bg-blue-500 text-white">{model.title}</span>;
            })}
          </div>
          <div className="flex items-center mb-4">
            <span className="text-xl font-semibold">NPR. {product.price}</span>
            {product.discount > 0 && (
              <span className="ml-2 text-gray-500 line-through">NPR. {product.price + product.discount}</span>
            )}
          </div>
          <div className="flex flex-wrap gap-2">
            <button
              onClick={() => setEditForm(true)}
              className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Edit
            </button>
            {/* generate a delete button */}
            <button
              onClick={deleteProduct}
              className="bg-red-500 text-white px-4 py-2 rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            >
              Delete
            </button>
            <button
              onClick={() => window.open('https://casemandu.com.np/offers/' + product.slug, '_blank')}
              className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              Visit
            </button>
          </div>
        </div>
      </div>

      {editForm && (
        <div
          className="fixed top-0 left-0 w-full backdrop-blur-md flex justify-center items-center h-screen transition-all"
          style={{ zIndex: 9999, backgroundColor: '#000000A0' }}
        >
          <div className="flex md:flex-row flex-col h-4/5 overflow-y-scroll md:w-8/12 w-full md:max-w-none max-w-xl bg-white">
            <form id="editForm" className="w-full   scroll-m-28 bg-white shadow-md p-8">
              <h2 className="text-2xl font-semibold mb-4">Edit Product</h2>
              {/* Title */}
              <div className="mb-4">
                <label htmlFor="title" className="block text-gray-700 font-semibold mb-2">
                  Title
                </label>
                <input
                  defaultValue={product?.title}
                  type="text"
                  id="title"
                  className="w-full outline-none bg-gray-200 p-2 border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              {/* Description */}
              <div className="mb-4">
                <label htmlFor="description" className="block text-gray-700 font-semibold mb-2">
                  Description
                </label>
                <textarea
                  defaultValue={product?.description}
                  id="description"
                  className="w-full outline-none bg-gray-200 p-2 border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  rows="4"
                ></textarea>
              </div>
              {/* Price */}
              <div className="mb-4">
                <label htmlFor="price" className="block text-gray-700 font-semibold mb-2">
                  Price
                </label>
                <input
                  defaultValue={product?.price}
                  type="number"
                  id="price"
                  className="w-full outline-none bg-gray-200 p-2 border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              {/* Discount */}
              <div className="mb-4">
                <label htmlFor="discount" className="block text-gray-700 font-semibold mb-2">
                  Discount
                </label>
                <input
                  defaultValue={product?.discount}
                  type="number"
                  id="discount"
                  className="w-full outline-none bg-gray-200 p-2 border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              {/* models */}
            </form>
            <div className="flex flex-col p-6 w-full bg-white">
              <div className="mb-4">
                <label htmlFor="image" className="block text-gray-700 font-semibold mb-2">
                  Image
                </label>
                <input
                  defaultValue={product?.image}
                  type="text"
                  id="image"
                  className="w-full outline-none bg-gray-200 p-2 border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <img src={product?.image} alt={product?.title} className="md:w-full  object-contain h-4/5" />
              <div className="mb-4">
                <label htmlFor="models" className="block text-gray-700 font-semibold mb-2">
                  Select Brand
                </label>
                <select
                  size="lg"
                  label="brands"
                  onChange={(e) => {
                    const modelss = brand.find((model) => model._id === e.target.value);
                    setAllModels(modelss?.models);
                  }}
                  className="
                            bg-white
                            border
                            border-gray-400
                            hover:border-gray-500
                            px-4
                            py-2
                            pr-8
                            w-full
                            rounded
                            shadow
                            leading-tight
                            focus:outline-none
                            focus:shadow-outline
                            appearance-none
                            
                            "
                >
                  <option value="" disabled selected>
                    Select Brand
                  </option>
                  {brand?.map((model) => {
                    return <option value={model._id}>{model.name}</option>;
                  })}
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="models" className="block text-gray-700 font-semibold mb-2">
                  Models
                </label>
                <select
                  size="lg"
                  label="Models"
                  onChange={(e) => pushModels(e, e.target.value)}
                  className="
                            bg-white
                            border
                            border-gray-400
                            hover:border-gray-500
                            px-4
                            py-2
                            w-full
                            pr-8
                            rounded
                            shadow
                            leading-tight
                            focus:outline-none
                            focus:shadow-outline
                            appearance-none
                            
                            "
                >
                  <option value="" disabled selected>
                    Select Models
                  </option>
                  {allmodels?.map((model) => {
                    return <option value={model?.name}>{model.name}</option>;
                  })}
                </select>
              </div>
              {/* models */}
              <div className="flex flex-wrap max-w-full gap-2 p-2  max-h-96">
                {selectedModels?.map((model) => {
                  return (
                    <div className="p-2  flex gap-2 items-center bg-gray-200 rounded" onClick={() => popModel(model)}>
                      {' '}
                      {model}
                      <p className="h-5 w-5 flex justify-center items-center cursor-pointer hover:bg-red-200 text-xs rounded-full bg-red-300  text-white">
                        X
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className="flex gap-2 justify-end pb-5">
                <button
                  onClick={() => setEditForm(false)}
                  type="button"
                  className="bg-gray-500 text-white px-4 py-2 rounded-full hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  Cancel
                </button>

                <button
                  onClick={() => reset()}
                  type="button"
                  className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Reset
                </button>

                <button
                  type="submit"
                  onClick={saveForm}
                  className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleOffer;
