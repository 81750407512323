export default function logout(saveUsers, dispatch) {
  dispatch(saveUsers({}));

  // remove users from local storage
  localStorage.removeItem('users');

  const data = {
    success: true,
    message: 'Logged out successfully',
  };
  return data;
}
