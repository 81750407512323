/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getAllBrand from 'src/functions/brand/getallBrand';
import { useSelector } from 'react-redux';
import getAllModel from 'src/functions/models/getAllModels';

function BrandPage() {
  const url = process.env.REACT_APP_URL;
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const { model: phones } = useSelector((state) => state.model);
  const [loading, setLoading] = useState(true);
  const { brands } = useSelector((state) => state.brand);
  const [brand, setBrand] = useState(null);
  const { users } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [link, setLink] = useState(null);

  async function fetchBrand() {
    const token = 'abc';
    // const models = await getAllModel(dispatch, token);
    const brands = await getAllBrand(dispatch, token);
    setBrand(brands);
  }

  useEffect(() => {
    if (!!brand) {
      setLoading(false);
    }
  }, [brand]);

  // add a new brand
  function upload(e) {
    const formData = new FormData();
    formData.append('image', image);
    fetch('https://api.imgbb.com/1/upload?key=' + process.env.REACT_APP_IMGBB, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        const imageUrl = data.data.display_url;
        fetch(`${url}/api/phones/brands`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${users?.token}`,
          },
          body: JSON.stringify({
            name: link,
            thumbnail: imageUrl,
          }),
        })
          .then((res) => {
            if (!res.ok) {
              toast('Failed to add brand');
              throw new Error('Failed to add brand');
            }
            return res.json();
          })
          .then((data) => {
            if (data.error) return toast(data.error);
            fetchBrand();
            setShowAdd(false);
            addedNotify();
            setImage(null);
          });
      });
  }

  const addedNotify = () => toast('Brand added successfully!');

  useEffect(() => {
    fetchBrand();
  }, [phones]);

  const [showAdd, setShowAdd] = useState(false);

  function navto(e) {
    navigate(`/dashboard/brands/${e}`);
  }

  return (
    <div className="flex flex-col gap-2 p-5">
      <div className="flex pb-2 border-b-2 flex-row gap-3 justify-between">
        {/* generate a back button */}
        <div className="flex gap-3 items-center">
          <button className="bg-blue-500 hover:bg-blue-700 text-white h-8 w-8 flex justify-center items-center font-bold py-2 px-4 rounded-full">
            &lt;-
          </button>
          {/* generate a header with asd written */}
          <h1 className="text-xl font-bold">({brand?.length}) Brands added</h1>
        </div>
        <ToastContainer />
        {/* generate a button with add new image written */}
        <div
          onClick={(e) => setShowAdd(!showAdd)}
          className="flex gap-3 items-center bg-blue-100 p-2 rounded-xl hover:cursor-pointer hover:bg-blue-200 transition-all"
        >
          <button className="bg-blue-500 hover:bg-blue-700 text-white h-8 w-8 flex justify-center items-center font-bold py-2 px-4 rounded-full">
            {showAdd ? '-' : '+'}
          </button>
          <p className="hidden md:flex">{showAdd ? 'Cancel' : 'Add New Brand'}</p>
        </div>
      </div>

      {showAdd && (
        <div className="flex flex-col p-5 rounded bg-white shadow-lg transition-all">
          <h2 className="text-2xl font-semibold mb-4">Add New Brand</h2>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              {/* eslint-disable-next-line */}
              <label htmlFor="link" className="text-sm font-medium">
                Brand Name:
              </label>
              <input
                type="text"
                onChange={(e) => setLink(e.target.value)}
                name="link"
                id="link"
                placeholder="Enter Brand Name"
                className="border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="image" className="text-sm font-medium">
                Brand Thumbnail:
              </label>
              <input
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
                name="image"
                id="image"
                placeholder="Choose Image"
                className="border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="image" className="text-sm font-medium">
                Brand Thumbnail Preview:
              </label>
              <img
                src={image ? URL.createObjectURL(image) : 'https://via.placeholder.com/150'}
                alt="preview"
                className="h-32 w-32 object-contain"
              />
            </div>

            <div className="flex gap-4 mt-4">
              <button
                onClick={upload}
                className="bg-blue-500 hover:bg-blue-700 text-white flex justify-center items-center py-2 px-4 rounded-full transition-all"
              >
                Add
              </button>
              <button
                onClick={(e) => {
                  setShowAdd(false);
                  setImage(null);
                }}
                className="bg-red-500 hover:bg-red-700 text-white flex justify-center items-center py-2 px-4 rounded-full transition-all"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* create a table  */}
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300">Brand Name</th>

            <th className="border border-gray-300">Action</th>
          </tr>
        </thead>
        {(loading && (
          <tbody>
            {[1, 2, 3, 4, 5].map((brand, index) => (
              <tr key={index}>
                <td className="border flex items-center gap-5 border-gray-300 p-3 pl-5">
                  <div className="h-14 aspect-square object-contain w-14 bg-gray-300 rounded-md animate-pulse" />
                  <span className="p-3 bg-gray-300 rounded-md animate-pulse w-52"></span>
                </td>
                <td className="border border-gray-300 p-4 pl-5">
                  <button className=" hover:bg-blue-700 text-white flex justify-center items-center p-4 w-32  transition-all bg-gray-300 rounded-md animate-pulse"></button>
                </td>
              </tr>
            ))}
          </tbody>
        )) || (
          <tbody>
            {brand?.map((brand) => (
              <tr key={brand?._id}>
                <td className="border flex items-center gap-5 border-gray-300 p-3 pl-5">
                  <img src={brand?.thumbnail} className="h-14 aspect-square object-contain w-14" alt="thumb" />
                  <span className="pl-3">{brand?.name}</span>
                </td>
                <td className="border border-gray-300 p-4 pl-5">
                  <button
                    onClick={(e) => navto(brand?._id)}
                    className="bg-blue-500  hover:bg-blue-700 text-white flex justify-center items-center py-1 px-4 rounded-full transition-all"
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
}

export default BrandPage;
