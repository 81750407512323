import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import Order from './pages/Order';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import SingleOrder from './pages/SingleOrder';
import SingleProduct from './pages/SingleProduct';
import SliderImage from './pages/SliderImage';
import HappyCustomer from './pages/HappyCustomer';
import BrandPage from './pages/BrandPage';
import BannerImage from './pages/BannerImages';
import ModelPage from './pages/ModelPage';
import OfferPage from './pages/OfferPage';
import SingleOffer from './pages/SingleOffer';
import Customize from './pages/Customize';
import CustomizeModel from './pages/CustomizeModels';
import Promocode from './pages/Promocode';
import SinglePromo from './pages/SinglePromo';
import CreatePromo from './pages/CreatePromo';
import Casetype from './pages/Casetype';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/orders" />, index: true },
        { path: 'app', element: <Order /> },
        { path: 'orders', element: <Order /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'offers', element: <OfferPage /> },
        { path: 'offers/:id', element: <SingleOffer /> },
        { path: 'orders/:id', element: <SingleOrder /> },
        { path: 'products/:id', element: <SingleProduct /> },
        { path: 'slider', element: <SliderImage /> },
        { path: 'customer', element: <HappyCustomer /> },
        { path: 'brands', element: <BrandPage /> },
        { path: 'casetype', element: <Casetype /> },
        { path: 'brands/:id', element: <ModelPage /> },
        { path: 'banner', element: <BannerImage /> },
        { path: 'customize', element: <Customize /> },
        { path: 'customize/:id', element: <CustomizeModel /> },
        { path: 'promo/:id', element: <SinglePromo /> },
        { path: 'promo', element: <Promocode /> },
        { path: 'promo/create', element: <CreatePromo /> },

        // { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
