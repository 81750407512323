// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Orders',
    path: '/dashboard/orders',
    icon: icon('ic_user'),
  },
  {
    title: 'product',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
  },
  {
    title: 'offers',
    path: '/dashboard/offers',
    icon: icon('ic_cart'),
  },
  {
    title: 'PromoCodes',
    path: '/dashboard/promo',
    icon: icon('ic_lock'),
  },
  // {
  //   title: 'Sliders',
  //   path: '/dashboard/slider',
  //   icon: icon('ic_lock'),
  // },
  {
    title: 'Happy Customer',
    path: '/dashboard/customer',
    icon: icon('ic_user'),
  },
  {
    title: 'Brands',
    path: '/dashboard/brands',
    icon: icon('ic_cart'),
  },
  
  // {
  //   title: 'Banners',
  //   path: '/dashboard/banner',
  //   icon: icon('ic_user'),
  // },
  {
    title: 'Customize',
    path: '/dashboard/customize',
    icon: icon('ic_cart'),
  },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
];

export default navConfig;
