/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import { AiFillDelete, AiFillPushpin } from 'react-icons/ai';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { set } from 'lodash';

function CustomizeModel() {
  const url = process.env.REACT_APP_URL;
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [Data, setData] = useState([]);
  const [image, setImage] = useState(null);
  const [userModel, setUserModel] = useState('');
  const [userModelName, setUserModelName] = useState('');
  const [editCardPos, setEditCardPos] = useState({});
  const { users } = useSelector((state) => state.user);
  const [models, setModels] = useState([]);
  const [editedData, setEditedData] = useState(null);
  const [editedCard, setEditedCard] = useState(null);
  const [userInput, SetuserInput] = useState({
    model: '',
    aspectRatio: {
      width: 0,
      height: 0,
    },
    price: 599,
    templateImg: '',
  });

  function changeName(e) {
    // remove .png from the name
    e = e.split('.')[0].toLowerCase();
    const getfromModel = models.filter((model) => model?.name.toLowerCase() === e);
    if (getfromModel.length > 0) {
      setUserModel(getfromModel[0]._id);
      setUserModelName(e);
    }
  }

  function uploadData(e) {
    e.preventDefault();
    if (!!image && !!userModel && !!userInput.aspectRatio && !!userInput.price) {
      const formData = new FormData();
      formData.append('image', image);
      fetch('https://api.imgbb.com/1/upload?key=' + process.env.REACT_APP_IMGBB, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          const imageUrl = data.data.display_url;
          fetch(`${url}/api/phones/models/customize/${id}?modelID=${userModel}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${users?.token}`,
            },
            body: JSON.stringify({
              name: userModelName,
              ratio: userInput.aspectRatio,
              price: userInput.price,
              templateImg: imageUrl,
            }),
          })
            .then((res) => {
              if (!res.ok) {
                throw new Error('Failed to add model');
              }
              return res.json();
            })
            .then((data) => {
              setShowAdd(false);
              setImage(null);
              SetuserInput({
                model: '',
                aspectRatio: {
                  width: 9,
                  height: 16,
                },
                price: 599,
                templateImg: '',
              });
              fetchAllModels();
              addedNotify();
            });
        })

        .catch((error) => console.error(error));
    } else {
      if (!userModel) {
        toast('Please select a models');
        return;
      }
      if (!userInput.aspectRatio) {
        toast('Please enter aspect ratio');
        return;
      }
      if (!userInput.price) {
        toast('Please enter price');
        return;
      }
      if (!image) {
        toast('Please select an image');
        return;
      }
    }
  }

  function fetchAllModels(e) {
    fetch(`${url}/api/phones/brands/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        const models = data?.models;
        setModels(models);
        const filteredModels = models?.filter((model) => {
          return !!model.templateImg;
        });
        console.log('filteredModels', filteredModels);
        setData(filteredModels);
      });
  }

  function deleteData(e) {
    fetch(`${url}/api/phones/models/customize/${id}?modelID=${e}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${users?.token}`,
      },
      body: JSON.stringify({
        ratio: 0,
        price: 0,
        templateImg: '',
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to delete model');
        }
        return res.json();
      })
      .then((data) => {
        fetchAllModels();
        deleteNotify();
      })
      .catch((error) => console.error(error));
  }

  function updateData(e) {
    if (editedData.templateImg) {
      const formData = new FormData();
      formData.append('image', editedData.templateImg);
      fetch('https://api.imgbb.com/1/upload?key=' + process.env.REACT_APP_IMGBB, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          const imageUrl = data.data.display_url;
          fetch(`${url}/api/customizes/${e}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${users?.token}`,
            },
            body: JSON.stringify({
              ratio: editedData?.aspectRatio,
              price: editedData?.price,
              templateImg: imageUrl,
            }),
          })
            .then((res) => {
              if (!res.ok) {
                throw new Error('Failed to update');
              }
              return res.json();
            })
            .then((data) => {
              // fetchData();
              fetchAllModels();
              updateNotify();
              setSelectedData(null);
            });
        })
        .catch((error) => console.error(error));
    } else {
      fetch(`${url}/api/customizes/${e}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ratio: editedData?.aspectRatio,
          price: editedData?.price,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          // fetchData();
          fetchAllModels();

          updateNotify();
          setSelectedData(null);
        });
    }
    // scroll to the position
    window.scrollTo({
      top: editCardPos.top,
      left: editCardPos.left,
      behavior: 'smooth',
    });

    // blink the card 2 times with border green
    const card = document.getElementById(editCardPos.id);
    card.style.border = '2px solid green';
    setTimeout(() => {
      card.style.border = 'none';
    }, 500);
    setTimeout(() => {
      card.style.border = '2px solid green';
    }, 1000);
    setTimeout(() => {
      card.style.border = 'none';
    }, 1500);

    setEditedCard(...editedCard, editCardPos.id);

    // set the selected data to null
    setSelectedData(null);
  }

  const deleteNotify = () => toast('The selected Model deleted successfully!');
  const addedNotify = () => toast('Model added successfully!');
  const updateNotify = () => toast('Model updated successfully!');

  useEffect(() => {
    fetchAllModels();
  }, []);

  const [showAdd, setShowAdd] = useState(false);

  const [selectedData, setSelectedData] = useState(null);

  if (loading) return <h1>Loading...</h1>;

  return (
    <div className="flex flex-col gap-2 p-5">
      <div className="flex pb-2 border-b-2 flex-row gap-3 justify-between">
        {/* generate a back button */}
        <div className="flex gap-3 items-center">
          <button
            onClick={() => navigate('/dashboard/customize')}
            className="bg-blue-500 hover:bg-blue-700 text-white h-8 w-8 flex justify-center items-center font-bold py-2 px-4 rounded-full"
          >
            &lt;-
          </button>
          {/* generate a header with asd written */}
          <h1 className="text-xl font-bold">({Data?.length}) Models available</h1>
        </div>
        <ToastContainer />
        {/* generate a button with add new image written */}
        <div
          onClick={(e) => {
            setShowAdd(!showAdd);
            SetuserInput({
              model: '',
              aspectRatio: {
                width: 9,
                height: 16,
              },
              price: 599,
              templateImg: '',
            });
          }}
          className="flex gap-3 items-center bg-blue-100 p-2 rounded-xl hover:cursor-pointer hover:bg-blue-200 transition-all"
        >
          <button className="bg-blue-500 hover:bg-blue-700 text-white h-8 w-8 flex justify-center items-center font-bold py-2 px-4 rounded-full">
            {showAdd ? '-' : '+'}
          </button>
          <p className="hidden md:block">{showAdd ? 'Cancel' : 'Add New Model'}</p>
        </div>
      </div>

      {showAdd && (
        <div className="flex flex-col p-5 rounded bg-white shadow-lg transition-all">
          <h2 className="text-2xl font-semibold mb-4">Add New Model</h2>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              {/* eslint-disable-next-line */}
              <label htmlFor="model" className="text-sm font-medium">
                Model Name
              </label>
              <Select
                options={
                  models.map((model, index) => {
                    return {
                      value: model?._id,
                      label: model.name,
                    };
                  }) || []
                }
                defaultValue={userModel}
                isClearable
                isSearchable
                onChange={(e) => {
                  SetuserInput({
                    ...userInput,
                    model: e?.value,
                  });
                  setUserModel(e?.value);
                  setUserModelName(e?.label);
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              {/* eslint-disable-next-line */}
              <label htmlFor="aratio" className="text-sm font-medium">
                Aspect Ratio:
              </label>
              <input
                type="text"
                defaultValue={'9:16'}
                onChange={(e) => {
                  SetuserInput({
                    ...userInput,
                    aspectRatio: {
                      width: e.target.value.split(':')[0],
                      height: e.target.value.split(':')[1],
                    },
                  });
                }}
                name="aratio"
                id="aratio"
                placeholder="Aspect Ratio: 9:16 (width:height)"
                className="border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="flex flex-col gap-2">
              {/* eslint-disable-next-line */}
              <label htmlFor="price" className="text-sm font-medium">
                Price
              </label>
              <input
                type="number"
                defaultValue={userInput.price}
                onChange={(e) => {
                  SetuserInput({
                    ...userInput,
                    price: e.target.value,
                  });
                }}
                name="price"
                id="price"
                placeholder="Enter Price"
                className="border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="flex flex-col gap-2">
              {/* eslint-disable-next-line */}
              <label htmlFor="image" className="text-sm font-medium">
                Image:
              </label>
              {!image && (
                <input
                  type="file"
                  onChange={(e) => {
                    changeName(e.target.files[0].name);
                    setImage(e.target.files[0]);
                  }}
                  name="image"
                  id="image"
                  accept="image/*"
                  placeholder="Image"
                  className="border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                />
              )}

              {image && (
                <div className="flex flex-col w-32 p-2 bg-blue-gray-50 rounded-md">
                  <img src={URL.createObjectURL(image)} className="h-32 object-contain w-32" alt="image" />
                  <button
                    onClick={(e) => {
                      setImage(null);
                      SetuserInput({
                        ...userInput,
                        templateImg: '',
                      });
                      setUserModel('');
                    }}
                    className="bg-green-500 hover:bg-green-700 text-white flex justify-center items-center py-1 px-2 rounded-full transition-all"
                  >
                    <AiFillDelete />
                  </button>
                </div>
              )}
            </div>
            <div className="flex gap-4 mt-4">
              <button
                onClick={uploadData}
                className="bg-blue-500 hover:bg-blue-700 text-white flex justify-center items-center py-2 px-4 rounded-full transition-all"
              >
                Add
              </button>
              <button
                onClick={(e) => {
                  setShowAdd(false);
                  setImage(null);
                  SetuserInput({
                    model: '',
                    aspectRatio: {
                      width: 9,
                      height: 16,
                    },
                    price: 599,
                    templateImg: '',
                  });
                }}
                className="bg-red-500 hover:bg-red-700 text-white flex justify-center items-center py-2 px-4 rounded-full transition-all"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {!!selectedData && (
        <div className="flex flex-col p-5 rounded bg-white shadow-lg transition-all">
          <h2 className="text-2xl font-semibold mb-4">Edit Model</h2>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              {/* eslint-disable-next-line */}
              <label htmlFor="link" className="text-sm font-medium">
                Model Name:
              </label>
              <input
                type="text"
                defaultValue={selectedData?.name}
                readOnly
                name="link"
                id="link"
                placeholder="Enter Model Name"
                className="border border-gray-300 bg-gray-200 p-2 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="flex flex-col gap-2">
              {/* eslint-disable-next-line */}
              <label htmlFor="ratio" className="text-sm font-medium">
                Aspect Ratio:
              </label>
              <input
                type="text"
                defaultValue={selectedData?.ratio?.width + ':' + selectedData?.ratio?.height}
                name="ratio"
                id="ratio"
                placeholder="Default 9:16"
                onChange={(e) => {
                  setEditedData({
                    ...editedData,
                    aspectRatio: {
                      width: e.target.value.split(':')[0],
                      height: e.target.value.split(':')[1],
                    },
                  });
                }}
                className="border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="flex flex-col gap-2">
              {/* eslint-disable-next-line */}
              <label htmlFor="price" className="text-sm font-medium">
                Price
              </label>
              <input
                type="text"
                defaultValue={selectedData?.price}
                name="price"
                id="price"
                onChange={(e) => {
                  setEditedData({
                    ...editedData,
                    price: e.target.value,
                  });
                }}
                placeholder="Enter Model Price"
                className="border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="flex flex-col gap-2">
              {/* eslint-disable-next-line */}
              <label htmlFor="image" className="text-sm font-medium">
                Image:
              </label>
              <input
                type="file"
                onChange={(e) => {
                  setEditedData({
                    ...editedData,
                    templateImg: e.target.files[0],
                  });
                }}
                name="image"
                id="editImage"
                accept="image/*"
                placeholder="Image"
                className="border border-gray-300 p-2 rounded-md focus:outline-none hidden focus:border-blue-500"
              />

              {selectedData.templateImg && (
                <div className="flex flex-col w-32 p-2 bg-blue-gray-50 rounded-md">
                  <img
                    src={
                      (editedData?.templateImg && URL.createObjectURL(editedData?.templateImg)) ||
                      selectedData.templateImg
                    }
                    className="h-32 object-contain w-32"
                    alt="image"
                  />
                  <button
                    onClick={(e) => {
                      document.getElementById('editImage').click();
                      setEditedData({
                        ...editedData,
                        templateImg: '',
                      });
                    }}
                    className="bg-green-500 hover:bg-green-700 text-white flex justify-center items-center py-1 px-2 rounded-full transition-all"
                  >
                    Change
                  </button>
                </div>
              )}
            </div>

            <div className="flex gap-4 mt-4">
              <button
                onClick={() => updateData(selectedData._id)}
                className="bg-blue-500 hover:bg-blue-700 text-white flex justify-center items-center py-2 px-4 rounded-full transition-all"
              >
                Update
              </button>
              <button
                onClick={(e) => {
                  setSelectedData(null);
                  setEditedData(null);
                }}
                className="bg-red-500 hover:bg-red-700 text-white flex justify-center items-center py-2 px-4 rounded-full transition-all"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* generate a slider with images */}
      {(Data?.length > 0 && (
        <div
          className="
          grid grid-cols-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 flex-wrap
        "
        >
          {Data.map((data, index) => {
            return (
              <div
                id={`act${index}`}
                className={`flex flex-row md:w-auto w-5/12 border-2 ${
                  (editedCard?.includes(`act${index}`) && 'border-green-500') || ' border-[#0000002a]'
                } `}
                key={index}
              >
                <div className="flex gap-3 bg-[#d6d6d610] p-2 hover:scale-105 w-full cursor-pointer transition-all rounded flex-col">
                  <img src={data?.templateImg} className="h-32 object-contain w-32" alt="image" />
                  <p
                    className="text-black font-bold line-clamp-1
                  "
                  >
                    {data?.name}
                  </p>
                  <div className="flex gap-3">
                    <button
                      onClick={(e) => deleteData(data._id)}
                      className="bg-red-500 hover:bg-red-700 text-white flex justify-center items-center py-1 px-2 rounded-full transition-all"
                    >
                      Delete
                    </button>
                    <button
                      onClick={(e) => {
                        // go to top
                        window.scrollTo(0, 100);
                        setEditCardPos({
                          top: document.getElementById(`act${index}`).offsetTop,
                          left: document.getElementById(`act${index}`).offsetLeft,
                          id: 'act' + index,
                        });

                        setSelectedData(data);
                      }}
                      className="bg-green-500 hover:bg-green-700 text-white flex justify-center items-center py-1 px-2 rounded-full transition-all"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )) || (
        <div className="flex justify-center h-96 items-center ">
          <div className="flex flex-col p-5 gap-2 rounded bg-gray-200">
            <h1>No Models found. Add some</h1>
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomizeModel;
