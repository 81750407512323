// Define the action types
const FETCH_BRAND_REQUEST = 'FETCH_BRAND_REQUEST';
const FETCH_BRAND_SUCCESS = 'FETCH_BRAND_SUCCESS';
const FETCH_BRAND_FAILURE = 'FETCH_BRAND_FAILURE';

// Define the action creators
const fetchBrandRequest = () => {
  return {
    type: FETCH_BRAND_REQUEST,
  };
};

const fetchBrandSuccess = (brand) => {
  return {
    type: FETCH_BRAND_SUCCESS,
    payload: brand,
  };
};

const fetchBrandFailure = (error) => {
  return {
    type: FETCH_BRAND_FAILURE,
    payload: error,
  };
};

// Define the initial state
const initialState = {
  loading: false,
  brand: [],
  error: null,
};

// Define the brandReducer function
const brandReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BRAND_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        brand: action.payload,
      };
    case FETCH_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default brandReducer;
export { fetchBrandRequest, fetchBrandSuccess, fetchBrandFailure };
