import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import { useDispatch } from 'react-redux';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { UserListToolbar, UserListHead } from '../sections/@dashboard/user';
import { useSelector } from 'react-redux';
import fetchOrders from 'src/functions/orders/fetchOrders';
// sections
// mock
// import orders from '../_mock/user';

const relativeTime = require('dayjs/plugin/relativeTime');

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'order_item', label: 'Order Item', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  // { id: 'location', label: 'Location', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'actions', label: 'Action', alignRight: false },
  { id: '' },
];

export default function UserPage() {
  const navigation = useNavigate();
  const { orders } = useSelector((state) => state.orders);
  const users = useSelector((state) => state.user.users);
  const [jwtExpired, setJwtExpired] = useState(false);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(null);

  const [stat, setStat] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [SelectedItem, setSelectedItem] = useState({});

  const [showModel, setShowModel] = useState(false);

  const [loading, setLoading] = useState(true);

  async function getOrders() {
    setLoading(true);
    const ords = await fetchOrders(dispatch, users?.token);
    if (ords.success) {
      setJwtExpired(false);
      setLoading(false);
    } else {
      setLoading(true);
      setJwtExpired(true);
    }
  }

  useEffect(() => {
    getOrders();
  }, []);

  const handleOpenPop = (value) => {
    navigation(`/dashboard/orders/${value._id}`);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = orders.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  function openPop(e) {
    setOpen(null);
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0;

  const isNotFound = orders.length === 0;

  return (
    <div className="w-full ">
      <Helmet>
        <title> Orders | Casemandu </title>
      </Helmet>

      <Container
      // width="100%"
      >
        <Stack direction="row" alignItems="center" gap={2} mb={5}>
          <Typography variant="h4" gutterBottom>
            Orders
          </Typography>
          <Iconify
            onClick={() => getOrders()}
            className={'cursor-pointer hover:text-red-200 transition-colors'}
            icon={'solar:refresh-bold'}
            sx={{ mr: 2 }}
          />
        </Stack>
        {!loading && (
          <Card>
            <Scrollbar>
              <TableContainer padding="10px" sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={orders.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      const {
                        createdAt,
                        orderedAt,
                        name,
                        phone,
                        paymentMethod,
                        priceSummary,
                        orderItems,
                        status,
                        shippingAddress,
                        totalPrice,
                        shippingCharge,
                      } = row;
                      const selectedUser = selected.indexOf(name) !== -1;

                      const day = new Date(orderedAt);
                      dayjs.extend(relativeTime);
                      const isAfter = dayjs(day).fromNow();
                      return (
                        <TableRow hover key={index} tabIndex={-1} selected={selectedUser}>
                          <TableCell
                            onClick={() => handleOpenPop(row)}
                            className="cursor-pointer hover:text-blue-gray-500 transition-all"
                            component="th"
                            scope="row"
                            padding="5px"
                          >
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar
                                alt={name}
                                src={orderItems[0]?.image}
                                sx={{ width: 40, height: 40, borderRadius: 1.5 }}
                              />
                              <div class="flex flex-col">
                                <Typography variant="subtitle2" noWrap>
                                  {orderItems[0]?.name?.length > 20
                                    ? orderItems[0]?.name.substring(0, 20) + '...'
                                    : orderItems[0]?.name}
                                </Typography>
                                <Typography variant="subtitle5" noWrap>
                                  {moment(orderedAt).fromNow()}
                                </Typography>
                              </div>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <div class="flex flex-col">
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                              <Typography variant="subtitle4" noWrap>
                                {shippingAddress.length > 20
                                  ? shippingAddress.substring(0, 20) + '...'
                                  : shippingAddress}
                              </Typography>
                              <Typography variant="subtitle5" noWrap>
                                {phone}
                              </Typography>
                            </div>
                          </TableCell>

                          {/* <TableCell align="left">{shippingAddress}</TableCell> */}

                          <TableCell align="left">
                            <div
                              className={`flex px-3 rounded-full justify-center items-center py-1 border
                            ${
                              status === 'Pending'
                                ? 'border-blue-200 text-blue-800'
                                : status === 'SENT FOR DELIVERY'
                                ? 'border-yellow-200 text-yellow-800'
                                : 'border-green-200 text-green-800'
                            }
                            `}
                            >
                              {sentenceCase(status)}
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            NPR. {Number(totalPrice) + Number(shippingCharge) || Number(priceSummary.grandTotal)}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={(e) => handleOpenPop(row)}>
                              <Iconify icon={'mdi:eye'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>

              {showModel && (
                <div
                  className="flex justify-center items-center h-full w-full z-50 absolute top-0 left-0 "
                  style={{ backgroundColor: '#0000002a', backdropFilter: 'blur(2px)' }}
                >
                  <div className="flex flex-col h-auto w-11/12 rounded-xl shadow-lg relative overflow-hidden bg-white p-5 ">
                    <h2 className="text-xl font-bold">{SelectedItem.name}</h2>
                    <p>
                      {SelectedItem.shippingAddress} | {SelectedItem.phone}
                    </p>
                    <p>
                      Ordered : {dayjs(new Date(SelectedItem.orderedAt).toDateString()).fromNow()} ({' '}
                      {new Date(SelectedItem.orderedAt).toDateString()} )
                    </p>

                    <p>Special Note: {SelectedItem.additionalInfo}</p>

                    {/* make a close button absolute on right side */}
                    <div className="flex justify-end absolute right-2 top-2">
                      <div className="flex justify-end absolute right-2 top-2">
                        <button
                          onClick={() => setShowModel(false)}
                          className="p-2 rounded-full flex justify-center items-center h-8 w-8 bg-red-800 cursor-pointer text-white hover:bg-red-400"
                        >
                          X
                        </button>
                      </div>
                    </div>

                    <div className="w-full h-0.5 bg-gray-300 my-2" />
                    <div className="flex justify-between h-72 w-full">
                      <div className="flex flex-col gap-2 overflow-y-scroll h-72 w-1/2 p-2">
                        {SelectedItem.orderItems.map((item, index) => {
                          const title = item.name;
                          // Split the title using the "-" character as the delimiter
                          const parts = title.split(' - ');

                          const [titlename, caseType, phoneInfo] = parts;
                          let phoneModel = '';
                          let phoneBrand = '';
                          // Split phoneInfo to get phone model and brand
                          if (!!phoneInfo) {
                            [phoneModel, phoneBrand] = phoneInfo.split(',');
                          }

                          return (
                            <div className="flex pb-2 border-b-2 border-black" key={index}>
                              <img src={item.image} alt="" className="w-20 object-cover rounded-xl" />
                              <div className="flex flex-col gap-2 ml-2">
                                <h3 className="font-bold">{titlename}</h3>
                                {phoneInfo && (
                                  <p>
                                    <span>{phoneBrand}</span> | <span>{phoneModel}</span>
                                  </p>
                                )}
                                {caseType && <p>{caseType}</p>}
                                <p>
                                  {item.qty} Pcs | {item.price} NPR
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {/* generate a verticle line */}
                      <div className="w-0.5 h-full bg-gray-300" />
                      <div className="w-1/2 p-2">
                        <h3>Payment Method: {SelectedItem.paymentMethod}</h3>
                        <p>
                          Total Payable amount: {Number(SelectedItem.totalPrice) + Number(SelectedItem.shippingCharge)}{' '}
                          NPR
                        </p>
                        {SelectedItem.paymentMethod !== 'cash' && (
                          <img src={SelectedItem.paymentImage} alt="" className="max-h-52 w-auto" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={orders.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}

        {loading && (
          <div>
            <h2> Loading ...</h2>
          </div>
        )}

        {jwtExpired && (
          <div className="absolute z-[99999] top-0 left-0 h-full w-full flex bg-black bg-opacity-25 backdrop-blur-md items-center justify-center">
            {/* session expired */}
            <div className="bg-white text-black p-5 rounded-xl flex flex-col gap-5">
              <h2 className="text-xl font-bold">Session Expired</h2>
              <p>Your session has expired. Please login again to continue.</p>
              <Button
                onClick={() => {
                  window.localStorage.removeItem('users');
                  window.location.href = '/login';
                }}
                variant="contained"
                className="bg-blue-500 text-white"
              >
                Login
              </Button>
            </div>
          </div>
        )}
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => openPop()}>
          <Iconify icon={'ph:eye-light'} sx={{ mr: 2 }} />
          View
        </MenuItem>

        <MenuItem>
          <Iconify icon={'subway:up-2'} sx={{ mr: 2, color: 'info.main' }} />
          {(stat === 'Processing' && 'Complete') || (stat === 'Pending' && 'Processing') || 'Complete'}
        </MenuItem>

        {/* <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem> */}
      </Popover>
    </div>
  );
}
