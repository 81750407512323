import { fetchModelFailure, fetchModelRequest, fetchModelSuccess } from 'src/reducer/modelReducer';

async function getAllModel(dispatch, token, id) {
  const url = process.env.REACT_APP_URL; // Replace with your actual product URL

  try {
    dispatch(fetchModelRequest());

    const response = await fetch(url + '/api/phones/brands/' + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch product');
    }

    const model = await response.json();
    dispatch(fetchModelSuccess(model));
    return model;
  } catch (error) {
    console.error(error);
    dispatch(fetchModelFailure(error));
    throw error;
  }
}

export default getAllModel;
