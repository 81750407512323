// Define the action types
const FETCH_MODEL_REQUEST = 'FETCH_MODEL_REQUEST';
const FETCH_MODEL_SUCCESS = 'FETCH_MODEL_SUCCESS';
const FETCH_MODEL_FAILURE = 'FETCH_MODEL_FAILURE';

// Define the action creators
const fetchModelRequest = () => {
  return {
    type: FETCH_MODEL_REQUEST,
  };
};

const fetchModelSuccess = (model) => {
  return {
    type: FETCH_MODEL_SUCCESS,
    payload: model,
  };
};

const fetchModelFailure = (error) => {
  return {
    type: FETCH_MODEL_FAILURE,
    payload: error,
  };
};

// Define the initial state
const initialState = {
  loading: false,
  model: [],
  error: null,
};

// Define the modelReducer function
const modelReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MODEL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_MODEL_SUCCESS:
      return {
        ...state,
        loading: false,
        model: action.payload,
      };
    case FETCH_MODEL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default modelReducer;
export { fetchModelRequest, fetchModelSuccess, fetchModelFailure };
