import { fetchProductsFailure, fetchProductsRequest, fetchProductsSuccess } from 'src/reducer/productReducer';

async function fetchProduct(dispatch, token) {
  const url = process.env.REACT_APP_URL; // Replace with your actual product URL

  try {
    dispatch(fetchProductsRequest());

    const response = await fetch(url + '/api/products', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch product');
    }

    const products = await response.json();
    dispatch(fetchProductsSuccess(products?.products));
    return products.products;
  } catch (error) {
    console.error(error);
    dispatch(fetchProductsFailure(error));
    throw error;
  }
}

export default fetchProduct;
