import React from "react";
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Typography
} from "@material-tailwind/react";
import { OfferAdd } from "../form/OfferAdd";

export default function OfferModel() {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(!open);

    function close() {
        setOpen(false)
    }

    return (
        <>
            <Button onClick={handleOpen} variant="gradient">
                Add Offer
            </Button>
            <Dialog open={open} handler={handleOpen}>
                <DialogHeader className="flex justify-between items-center">
                    <h2>Add a Offer</h2>
                    <Button
                        color="red"
                        buttonType="link"
                        onClick={(e) => handleOpen(e)}
                        ripple="dark"
                    >
                        <i className="material-icons">close</i>
                    </Button>


                </DialogHeader>
                <DialogBody divider>
                    {/* eslint-disable-next-line */}
                    <OfferAdd close={close} />
                </DialogBody>

            </Dialog >
        </>
    );
}