/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import getAllBrand from 'src/functions/brand/getallBrand';
import getAllModel from 'src/functions/models/getAllModels';
import { useDispatch, useSelector } from 'react-redux';
import { filter, get } from 'lodash';
import getTypes from 'src/functions/casetype/getTypes';

function ModelPage() {
  const url = process.env.REACT_APP_URL;
  const { users } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [models, setModels] = useState([]);
  const { id: brandId } = useParams();
  const [caseTypes, setCasetypes] = useState();
  const [updatedData, setUpdatedData] = useState({
    id: '',
    name: '',
  });
  const [updatedCaseTypes, setUpdatedCaseTypes] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    title: '',
    caseTypes: [],
  });
  const [caseType, setCaseType] = useState([]);
  const dispatch = useDispatch();
  const [filteredModel, setFilteredModel] = useState(null);
  if (!brandId) {
    navigate('/dashboard/brands');
  }

  async function fetchModelData(e) {
    const models = await getAllModel(dispatch, 'abc', brandId);
    setLoading(false);
    setModels(models?.models);
  }

  function upload(e) {
    if (data.title === '') {
      return toast('Please enter the Model Name');
    }
    if (data.caseTypes.length === 0) {
      return toast('Please select atleast one Case Type');
    }

    fetch(`${url}/api/phones/brands/` + brandId, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${users?.token}`,
      },
      body: JSON.stringify({
        name: data.title,
        caseTypes: data.caseTypes,
      }),
    })
      .then((res) => {
        if (res.status === 401) {
          return toast('Unauthorized');
        }
        return res.json();
      })
      .then((data) => {
        getAllModel(dispatch, 'abc');
        setLoading(true);
        fetchModelData('refetch');
        setShowAdd(false);
        addedNotify();
      });
  }

  async function deleteData(e) {
    fetch(`${url}/api/phones/models/${brandId}?modelID=${e}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${users?.token}`,
      },
    })
      .then((res) => {
        if (res.status === 401) {
          return toast('Unauthorized');
        }
        return res.json();
      })
      .then((data) => {
        setLoading(true);
        fetchModelData('refetch');
        deleteNotify();
      });
  }

  function updateData(e) {
    if (updatedCaseTypes.length === 0) {
      return toast('Please select atleast one Case Type');
    }
    fetch(`${url}/api/phones/models/${brandId}?modelID=${e}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${users?.token}`,
      },
      body: JSON.stringify({
        name: updatedData.name || selectedData.name,
        caseTypes: updatedCaseTypes,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          return toast.error('Error occured while Updating the Model');
        }
        return res.json();
      })
      .then((data) => {
        try {
          setLoading(true);
          fetchModelData('refetch');
          setSelectedData(null);
          updateNotify();
        } catch (error) {
          console.log(error);
        }
      });
  }

  function deleteBrand(e) {
    fetch(`${url}/api/phones/brands/${e}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${users?.token}`,
      },
    })
      .then((res) => {
        if (res.status === 401) {
          return toast('Unauthorized');
        }
        return res.json();
      })
      .then((data) => {
        getAllBrand(dispatch, 'abc');
        navigate(`/dashboard/brands`);
        deleteBrandNotify();
      });
  }

  async function getCaseTYpes() {
    const types = await getTypes(dispatch, 'abc');
    setCasetypes(types);
    // add case types to the data.caseTYpes
    setData({
      ...data,
      caseTypes: types.map((item) => item._id),
    });
  }

  const deleteNotify = () => toast('The selected Model deleted successfully!');
  const deleteBrandNotify = () => toast('The selected Brand deleted successfully!');
  const addedNotify = () => toast('Model added successfully!');
  const updateNotify = () => toast('Model updated successfully!');

  useEffect(() => {
    fetchModelData();
    getCaseTYpes();
  }, [brandId]);

  const [showAdd, setShowAdd] = useState(false);

  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    setUpdatedData({
      id: selectedData?._id,
    });
    setUpdatedCaseTypes(selectedData?.caseTypes);
  }, [selectedData]);

  return (
    <div className="flex flex-col gap-2 p-5">
      <div className="flex pb-2 border-b-2 flex-row gap-3 justify-between">
        {/* generate a back button */}
        <div className="flex gap-3 items-center">
          <button
            onClick={() => navigate('/dashboard/brands')}
            className="bg-blue-500 hover:bg-blue-700 text-white h-8 w-8 flex justify-center items-center font-bold py-2 px-4 rounded-full"
          >
            &lt;-
          </button>
          {/* generate a header with asd written */}
          <h1 className="text-xl font-bold">({models?.length}) Models available</h1>
        </div>
        <ToastContainer />
        {/* generate a button with add new image written */}
        <div
          onClick={(e) => setShowAdd(!showAdd)}
          className="flex gap-3 items-center bg-blue-100 p-2 rounded-xl hover:cursor-pointer hover:bg-blue-200 transition-all"
        >
          <button className="bg-blue-500 hover:bg-blue-700 text-white h-8 w-8 flex justify-center items-center font-bold py-2 px-4 rounded-full">
            {showAdd ? '-' : '+'}
          </button>
          <p className="hidden md:flex">{showAdd ? 'Cancel' : 'Add New Model'}</p>
        </div>
      </div>

      {showAdd && (
        <div className="flex flex-col p-5 rounded bg-white shadow-lg transition-all">
          <h2 className="text-2xl font-semibold mb-4">Add New Model</h2>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              {/* eslint-disable-next-line */}
              <label htmlFor="link" className="text-sm font-medium">
                Model Name:
              </label>
              <input
                type="text"
                onChange={(e) => {
                  setData({
                    ...data,
                    title: e.target.value,
                  });
                }}
                name="title"
                id="title"
                placeholder="Enter Model Name"
                className="border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="flex flex-col gap-2">
              {/* eslint-disable-next-line */}
              <label htmlFor="link" className="text-sm font-medium">
                Case Types:
              </label>
              <div className="flex gap-2 flex-col">
                {caseTypes?.map((caseType) => (
                  // checkbox for each case type
                  <label key={caseType._id} className="flex items-center gap-2">
                    <input
                      onChange={(e) => {
                        if (e.target.checked) {
                          setData({
                            ...data,
                            caseTypes: [...data.caseTypes, e.target.value],
                          });
                        } else {
                          setData({
                            ...data,
                            caseTypes: data.caseTypes.filter((item) => item !== e.target.value),
                          });
                        }
                      }}
                      type="checkbox"
                      name="caseType"
                      defaultChecked={true}
                      value={caseType._id}
                    />
                    {caseType.name}
                  </label>
                ))}
              </div>
            </div>
            <div className="flex gap-4 mt-4">
              <button
                onClick={upload}
                className="bg-blue-500 hover:bg-blue-700 text-white flex justify-center items-center py-2 px-4 rounded-full transition-all"
              >
                Add
              </button>
              <button
                onClick={(e) => {
                  setShowAdd(false);
                  setData({
                    title: '',
                    caseTypes: [],
                  });
                }}
                className="bg-red-500 hover:bg-red-700 text-white flex justify-center items-center py-2 px-4 rounded-full transition-all"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {!!selectedData && (
        <div className="flex flex-col p-5 rounded bg-white shadow-lg transition-all">
          <h2 className="text-2xl font-semibold mb-4">Edit Model</h2>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              {/* eslint-disable-next-line */}
              <label htmlFor="link" className="text-sm font-medium">
                Model Name:
              </label>
              <input
                type="text"
                defaultValue={selectedData.name}
                onChange={(e) =>
                  setUpdatedData({
                    ...selectedData,
                    name: e.target.value,
                  })
                }
                name="link"
                id="link"
                placeholder="Enter Model Name"
                className="border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="flex flex-col gap-2">
              {/* eslint-disable-next-line */}
              <label htmlFor="link" className="text-sm font-medium">
                Case Types:
              </label>
              <div className="flex flex-col gap-2">
                {caseTypes?.map((caseType) => (
                  // checkbox for each case type
                  <label key={caseType._id} className="flex items-center gap-2">
                    <input
                      onChange={(e) => {
                        if (e.target.checked) {
                          setUpdatedCaseTypes([...updatedCaseTypes, e.target.value]);
                        } else {
                          setUpdatedCaseTypes(updatedCaseTypes.filter((item) => item !== e.target.value));
                        }
                      }}
                      type="checkbox"
                      name="caseType"
                      defaultChecked={selectedData.caseTypes.filter((item) => item === caseType._id).length > 0}
                      value={caseType._id}
                    />
                    {caseType.name}
                  </label>
                ))}
              </div>
            </div>
            <div className="flex gap-4 mt-4">
              <button
                onClick={() => updateData(selectedData._id)}
                className="bg-blue-500 hover:bg-blue-700 text-white flex justify-center items-center py-2 px-4 rounded-full transition-all"
              >
                Update
              </button>
              <button
                onClick={(e) => setSelectedData(null)}
                className="bg-red-500 hover:bg-red-700 text-white flex justify-center items-center py-2 px-4 rounded-full transition-all"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* generate a slider with images */}
      {((!loading && models?.length) > 0 && (
        <div className="flex gap-5 flex-wrap justify-between md:justify-start">
          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr>
                <th className="border border-gray-300">Model Name</th>
                <th className="border border-gray-300">Case Types</th>
                <th className="border border-gray-300">Action</th>
              </tr>
            </thead>
            {(loading && (
              <tbody>
                {[1, 2, 3, 4, 5].map((model, index) => (
                  <tr key={index}>
                    <td className="border flex items-center gap-5 border-gray-300 p-3 pl-5">
                      <div className="h-14 aspect-square object-contain w-14 bg-gray-300 rounded-md animate-pulse" />
                      <span className="p-3 bg-gray-300 rounded-md animate-pulse w-52"></span>
                    </td>
                    <td className="border flex items-center gap-5 border-gray-300 p-3 pl-5">
                      <span className="p-3 bg-gray-300 rounded-md animate-pulse w-52"></span>
                    </td>
                    <td className="border border-gray-300 p-4 pl-5">
                      <button className=" hover:bg-blue-700 text-white flex justify-center items-center p-4 w-32 transition-all bg-gray-300 rounded-md animate-pulse"></button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )) || (
              <tbody>
                {models.map((item) => (
                  <tr key={item._id}>
                    <td className="border border-gray-300 pl-5">{item.name} </td>
                    <td className="border border-gray-300 pl-2">
                      {item?.caseTypes?.map((caseType) => (
                        <span key={caseType._id} className="bg-gray-200 px-2 py-1 rounded-full mr-1">
                          {/* {map caseType} */}

                          {!!caseType && caseTypes?.filter((item) => item?._id === caseType).map((item) => item.name)}
                        </span>
                      ))}
                    </td>
                    <td className="border flex gap-2 border-gray-300 p-4 pl-5">
                      <button
                        onClick={() => setSelectedData(item)}
                        className="bg-blue-500 hover:bg-blue-700 text-white flex justify-center items-center py-1 px-2 rounded-full transition-all"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => deleteData(item._id)}
                        className="bg-red-500 hover:bg-red-700 text-white flex justify-center items-center py-1 px-2 rounded-full transition-all"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      )) || (
        <div className="flex gap-5 flex-wrap justify-between md:justify-start">
          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr>
                <th className="border border-gray-300">Model Name</th>
                <th className="border border-gray-300">Case Types</th>
                <th className="border border-gray-300">Action</th>
              </tr>
            </thead>
            <tbody>
              {[1, 2, 3, 4, 5].map((model, index) => (
                <tr key={index}>
                  <td className="border flex items-center gap-5 border-gray-300 p-3 pl-5">
                    <div className="h-14 aspect-square object-contain w-14 bg-gray-300 rounded-md animate-pulse" />
                    <span className="p-3 bg-gray-300 rounded-md animate-pulse w-52"></span>
                  </td>
                  <td className="border flex items-center gap-5 border-gray-300 p-3 pl-5">
                    <span className="p-3 bg-gray-300 rounded-md animate-pulse w-52"></span>
                  </td>
                  <td className="border border-gray-300 p-4 pl-5">
                    <button className=" hover:bg-blue-700 text-white flex justify-center items-center p-4 w-32 transition-all bg-gray-300 rounded-md animate-pulse"></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {models?.length == 0 && (
        <tbody>
          <div className="flex justify-center h-96 items-center ">
            <div className="flex flex-col p-5 gap-2 rounded bg-gray-200">
              <h1>No Models found. You can now Delete the Brand</h1>
              <button
                onClick={() => deleteBrand(id)}
                className="bg-red-500 hover:bg-red-700 text-white flex justify-center items-center py-1 px-2 rounded-full transition-all"
              >
                Delete
              </button>
            </div>
          </div>
        </tbody>
      )}
    </div>
  );
}

export default ModelPage;
