import React from "react";
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Typography
} from "@material-tailwind/react";
import { ProductAdd } from "../form/ProductAdd";

export function ModelReact() {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(!open);

    return (
        <>
            <Button onClick={handleOpen} variant="gradient">
                Add Product
            </Button>
            <Dialog open={open} handler={handleOpen}>
                <DialogHeader className="flex justify-between items-center">
                    <h2>Add a product</h2>
                    <Button
                        color="red"
                        buttonType="link"
                        onClick={(e) => handleOpen(e)}
                        ripple="dark"
                    >
                        <i className="material-icons">close</i>
                    </Button>


                </DialogHeader>
                <DialogBody divider>
                    <ProductAdd />
                </DialogBody>

            </Dialog >
        </>
    );
}