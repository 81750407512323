import { fetchProductsFailure, fetchProductsRequest, fetchProductsSuccess } from 'src/reducer/productReducer';
import fetchProduct from './fetchProduct';

async function updateProduct(dispatch, token, slug, data) {
  const url = process.env.REACT_APP_URL; // Replace with your actual product URL

  try {
    dispatch(fetchProductsRequest());

    const response = await fetch(url + '/api/products/' + slug, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('Failed to update product');
    }
    await fetchProduct(dispatch, token);
    return true;
  } catch (error) {
    dispatch(fetchProductsFailure(error));
    return false;
  }
}

export default updateProduct;
